
import { Controller } from "stimulus"
import { navigator } from "@hotwired/turbo"

export default class extends Controller {
  static targets = [ "field", "form", "googlePlacesId", "googlePlacesName", "googlePlacesIcon"]

  connect() {
    if (typeof(google) != "undefined") {
      this.initMap()
    }
  }

  initMap() { 
    // render map for maps/show
    if (document.getElementById('map')) { showMap() }

    var options = {}

    if (this.data.get("country-restrictions")) {
      var country_restrictions = String(this.data.get("country-restrictions")).split(" ")
      options['componentRestrictions'] = { country: country_restrictions }
    }

    if (this.data.get("type-restrictions")) {
      var type_restrictions = String(this.data.get("type-restrictions"))
      options['types'] = [ type_restrictions ] 
    }
    
    this.autocomplete = new google.maps.places.Autocomplete(this.fieldTarget, options)
    this.autocomplete.setFields(['place_id', 'name', 'icon'])
    this.autocomplete.addListener('place_changed', this.placeChanged.bind(this))
  }

  placeChanged() {
    let place = this.autocomplete.getPlace()
    
    if (!place.name) {
      window.alert(`Kein Ergbenis für deine Eingabe: ${place.name}`)
      return
    }

    this.googlePlacesIdTarget.value    = place.place_id
    this.googlePlacesNameTarget.value  = place.name
    if (this.hasGooglePlacesIconTarget) { this.googlePlacesIconTarget.value = place.icon }
    // console.log(place.photos[10].getUrl(100))

    // map address components
    // for (const component of place.address_components) {
    //   const componentType = component.types[0];
  
    //   switch (componentType) {
    //     case "locality":
    //       this.googlePlacesNameTarget.value = component.long_name;
    //       break;

    //     case "country":
    //       if (this.hasGooglePlacesCountryCodeTarget) { this.googlePlacesCountryCodeTarget.value = component.short_name }
    //       break;
    //   }
    // }

    // console.log(place)

    // fire form if using location setter
    if (this.hasFormTarget) {
      // this.formTarget.requestSubmit()
      navigator.submitForm(this.formTarget)
      if (this.data.get("clear-input")) { this.clearInput() }
    }
  }

  clearInput() {
    this.fieldTarget.value = ''
  }

  keydown(event) {
    if (event.key == "Enter") {
      event.preventDefault()
    }
  }
}
