import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["file", "preview"]

  swapImage() {
    if (this.fileTarget.files && this.fileTarget.files[0]) {
      var reader = new FileReader();
      var preview_image_tag = this.previewTarget

      reader.onload = function (e) {
        // get loaded data and render thumbnail.
        preview_image_tag.src = e.target.result;
      };
      reader.readAsDataURL(this.fileTarget.files[0])
    }
  }
}
